import React from "react";
import { Row, Col } from "react-bootstrap";
import './Home.css';
import { Link } from "react-router-dom";
import GoldButton from "../components/GoldButton";
import ServiceCard from "../components/ServiceCard";

import Img1 from '../images/extensions2.jpeg';

import Project1 from '../images/hero-cover.jpeg';
import Project2 from '../images/project-hadley.jpeg';

import LoftConversions from "../images/loft-conversions.png";
import HomeExtensions from "../images/home-extensions.png";
import KitchenFitting from "../images/kitchen-fitting.png";
import Carpentry from "../images/carpentry.png";


const Home = () => {
  function restoreScroll() {
    window.scrollTo(0,0);
}

    return (
        <>
  <header>
    <div className="home-overlay" />
    <section className="home-header">
      <div className="hero-text">
        <h1 className="main-title">Welcome to KS Projects</h1>
        <h2 className="main-subtitle">Helping you Create your Dream Space</h2>
      </div>
    </section>
  </header>
  <main>
    <section className="home-info">
      <div className="home-info-content">
        <h2>The new way to property construction</h2>
        <Row className="row">
          <Col className="col col-md-6 col-12">
            <p>
              At KS Projects we are a team of highly qualified and experienced
              contractors who have a track record of producing exceptional work
              for our clients. We specialise in home extensions, complete home
              renovations, and all types of building work. With our wealth of
              expertise, we can deliver and exceed our customer’s expectations
              time and again.
            </p>
            <p>
              If you want to work with the one of the best domestics
              construction contractors in London and the surrounding areas get
              in touch today so we can get to work on transforming your
              property.
            </p>
          </Col>
          <Col className="col col-md-6 col-12">
            <img src={Img1} alt="" />
          </Col>
        </Row>
      </div>
    </section>
    <div className="unique-wrapper">
      <section className="unique">
        <h2>What sets us apart</h2>
        <Row className="row">
          <Col className="col col-md-6 col-12">
            <h3>1. Reliability</h3>
            <p>
              Dependability, consistency and top-quality resulsts are most
              important to you. We understand that. Our experience allows us to
              confidently deliver clients projects in an honest, realistic and
              responsible manner.
            </p>
          </Col>
          <Col className="col col-md-6 col-12">
            <h3>2. Communication</h3>
            <p>
              The key to a successful collaboration is a continuous flow of
              effective and efficient communication that keeps everyone informed
              at every step of the process. We strive to ensure that all
              enquiries are dealt with promptly and someone will always be on
              hand to provide assistance.
            </p>
          </Col>
          <Col className="col col-md-6 col-12">
            <h3>3. Quality</h3>
            <p>
              We endeavour to consistently produce high quality work. Mainting
              the highest standard possible is our way of showing respect to you
              and your property. We are constantly exceeding our benchmarks and
              aim to find tailored solutions to suit your needs, providing
              quaility that lasts.
            </p>
          </Col>
          <Col className="col col-md-6 col-12">
            <h3>4. Reputation</h3>
            <p>
              Our clients regard us as friends throughout the construction. They
              trust our company time and time again to provide the best results
              possible on their project. We build a strong relationship with
              clients to ensure everyone's needs are met and the project is a
              success.{" "}
            </p>
          </Col>
        </Row>
      </section>
    </div>
    <section className="services">
      <div className="services-content">
        <h2>What we do</h2>
        <Row className="row">
        <Col className="service-card col col-xl-3 col-md-6 col-12"><Link className="Link" to="/loft-conversions" onClick={restoreScroll}><ServiceCard name="Loft Conversions" imgSrc={LoftConversions} /></Link></Col>
        <Col className="service-card col col-xl-3 col-md-6 col-12"><Link className="Link" to="/home-extensions" onClick={restoreScroll}><ServiceCard name="Home Extensions" imgSrc={HomeExtensions} /></Link></Col>
        <Col className="service-card col col-xl-3 col-md-6 col-12"><Link className="Link" to="/kitchen-fitting" onClick={restoreScroll}><ServiceCard name="Kitchen Fitting" imgSrc={KitchenFitting} /></Link></Col>
        <Col className="service-card col col-xl-3 col-md-6 col-12"><Link className="Link" to="/carpentry" onClick={restoreScroll}><ServiceCard name="Carpentry" imgSrc={Carpentry} /></Link></Col>
        </Row>
        <Link className="Link buttonLink" to="/services" onClick={restoreScroll}><GoldButton text="See all our services →" /></Link>
      </div>
    </section>
    <section className="projects">
      <div className="projects-content">
        <h2>Projects</h2>
        <Row className="row">
          <Col className="col col-md-6 col-12">
            <img src={Project1} alt="" />
          </Col>
          <Col className="col col-md-6 col-12">
            <div className="content-wrapper">
              <h4 id="header-winchmore">Winchmore Hill swimming pool</h4>
              <p>
                A home swimming pool and gym construction in Winchmore Hill. A
                great addition to a large property, fully equipped with state of
                the art pool systems, sound and lighting systems and smart home
                features such as electronically closing blinds and remote
                controlled mood lighting.
              </p>
              <p>
                Our client also requested a home gym to be built by the side of
                the pool, kitted out with all the latest machines and weights,
                they have access to high-end leisure facilities 24/7.
              </p>
            </div>
          </Col>
        </Row>
        <Row className="row">
          <Col className="col col-md-6 col-12">
            <div className="content-wrapper">
              <h4 id="header-hadley">Hadley Wood home renovation</h4>
              <p>
                A full refurbishment and rear extension to an impressive house
                in Hadley Wood. Complete with a designer German kitchen and
                polished bathrooms, this house is fitted out with luxurious
                fixtures and fittings as well as a full smart home system.
              </p>
              <p>
                High end flooring and tiling make the entire property feel like
                a stately residence and the exterior design creates a
                sensational kerb appeal. Anthracite window frames and an
                electronic garage door contrast the white render perfectly,
                leaving a stunning impression on passers-by.
              </p>
            </div>
          </Col>
          <Col className="col col-md-6 col-12">
            <img src={Project2} alt="" />
          </Col>
        </Row>
      </div>
    </section>
    <section className="testimonials">
      <div className="testimonials-content">
        <h2>What our clients say</h2>
        <div
          id="tmn-carousel"
          className="carousel carousel-dark slide"
          data-bs-ride="carousel"
        >
          <div className="carousel-inner">
            <div className="carousel-item active">
              <blockquote className="review-text">
                <p>
                  I highly recommend KS Projects for all types of constructions
                  that they offer. I had my all rooms in my house painted and
                  decorated; they did a fantastic job! I had no complaints
                  after, all jobs were finished efficiently and to the standards
                  that I had asked for; even ensuring everyone's safety within
                  the house. Thank you!
                </p>
                <br />
                <br />
                <div className="blockquote-footer">Tia</div>
              </blockquote>
            </div>
            <div className="carousel-item">
              <blockquote className="review-text">
                <p>
                  Had a new kitchen and bathroom fitted. Very impressed with the
                  quality and the end result, I highly recommend. The
                  team handled the project very professionally and there were no
                  issues during the process.
                </p>
                <br />
                <br />
                <div className="blockquote-footer">Kuberan</div>
              </blockquote>
            </div>
            <div className="carousel-item">
              <blockquote className="review-text">
                <p>
                  Excellent service, very professional and top quality work,
                  highly recommended and will use again!
                </p>
                <br />
                <br />
                <div className="blockquote-footer">Bhavin</div>
              </blockquote>
            </div>
            <div className="carousel-item">
              <blockquote className="review-text">
                <p>
                  Very professional and hardworking team. They
                  can be relied upon to be conscientious and to deliver an
                  excellent outcome at a fair price.
                </p>
                <br />
                <br />
                <div className="blockquote-footer">Michael</div>
              </blockquote>
            </div>
            <div className="carousel-item">
              <blockquote className="review-text">
                <p>
                  KS Projects built me a new front extension and rear
                  extension as well as completed a full house refurbishment. The
                  whole process was without delays and they produced a high
                  quaility space. Definitely recommend and a huge thank you!
                </p>
                <br />
                <br />
                <div className="blockquote-footer">Mustafa</div>
              </blockquote>
            </div>
          </div>
          <button
            type="button"
            className="carousel-control-prev"
            data-bs-target="#tmn-carousel"
            data-bs-slide="prev"
          >
            <span className="carousel-control-prev-icon" />
          </button>
          <button
            type="button"
            className="carousel-control-next"
            data-bs-target="#tmn-carousel"
            data-bs-slide="next"
          >
            <span className="carousel-control-next-icon" />
          </button>
        </div>
      </div>
    </section>
    <div className="bottom-cta-wrapper">
      <div className="bottom-cta-overlay">
        <section className="bottom-cta">
          <div className="bottom-cta-content">
            <h2>Ready to discuss a project?</h2>
            <Link className="Link buttonLink" to="/contact" onClick={restoreScroll}><GoldButton text="Get in touch →" /></Link>
            <p>
              Contact us today to get a free, no-obligation quote so we can get
              started on transforming your property
            </p>
          </div>
        </section>
      </div>
    </div>
  </main>
</>

    )
}

export default Home;
