import React from "react";
import { Row, Col } from "react-bootstrap";
import './services.css';

import Carpentry1 from '../images/carpentry1.jpeg';
import Carpentry2 from '../images/carpentry2.jpeg';
import Carpentry3 from '../images/carpentry3.jpeg';
import Carpentry4 from '../images/carpentry4.jpeg';
import Carpentry5 from '../images/carpentry5.jpeg';

const Carpentry = () => {
    return (
        <>
        <header className="service-page">
            <img className="service-page-img" src={Carpentry3} alt="" />
            <div className="overlay" />
            <h2>Carpentry</h2>
        </header>
        <main className="service-main">
            <div className="service-main-wrapper">
            <Row className="welcome row">
            <Col className="col col-lg-6 col-md-6 col-12">
                <img src={Carpentry1} className="img-fluid" alt="" />
            </Col>
            <Col className="col col-lg-6 col-md-6 col-12">
                <p>
                <strong>

                We use the latest tools and equipment and our fully trained and experienced carpenters specialise in all types of carpentry work. Whether you need new stairs, doors, tables, shelves, or anything else, we can ensure that the work will be delivered with the utmost perfection and detail.
                </strong>
                </p>
                <p>
                With our wealth of experience, we guarantee a service delivered to an exceptional standard. Thanks to our expert knowledge and know-how, we are always available to advise our customers on the most suitable fittings for them and their needs. Our commitment to brilliance is clearly visible in our workmanship and amazing reviews from our very happy customers.
                </p>
                <p>
                Carpentry is essential as it creates the foundations of a truly beautiful home, and makes your home more practical and feels more spacious. For example, fitted-in-built shelves can make your rooms not only look more elegant but will also make your room feel larger and way more practical. every customer wants their belongings stored in a suitable location that can be easily accessed, but at the same time still looks great.
                </p>
            </Col>
            </Row>
            <Row className="gallery row mt-5 pt-5">
                <Col className="col col-lg-4 col-md-6 col-12 px-1">
                    <img className="img-fluid" src={Carpentry1} alt="" />
                    <img className="img-fluid" src={Carpentry2} alt="" />
                </Col>
                <Col className="col col-lg-4 col-md-6 col-12 px-1">
                    <img className="img-fluid" src={Carpentry3} alt="" />
                    <img className="img-fluid" src={Carpentry4} alt="" />
                </Col>
                <Col className="col col-lg-4 col-md-6 col-12 px-1">
                    <img className="img-fluid" src={Carpentry5} alt="" />
                </Col>
            </Row>
            </div>
        </main>
        </>
    )
}

export default Carpentry;