import React from "react";
import { Row, Col } from "react-bootstrap";
import './services.css';

import Landscaping1 from '../images/ls1.jpeg';
import Landscaping2 from '../images/ls2.jpeg';
import Landscaping3 from '../images/ls3.jpeg';
import Landscaping4 from '../images/ls4.jpeg';

const Landscaping = () => {
    return (
        <>
        <header className="service-page">
            <img className="service-page-img" src={Landscaping1} alt="" />
            <div className="overlay" />
            <h2>Landscaping</h2>
        </header>
        <main className="service-main">
            <div className="service-main-wrapper">
            <Row className="welcome row">
            <Col className="col col-lg-6 col-md-6 col-12">
                <img src={Landscaping2} className="img-fluid" alt="" />
            </Col>
            <Col className="col col-lg-6 col-md-6 col-12">
                <p>
                <strong>
                Thoughtful landscape design is the key to creating an outdoor space that truly revives and inspires you, whether you have a tiny courtyard in an urban area or a sprawling estate in the country.
                </strong>
                </p>
                <p>
                Everything from grass type to the design of the flower beds and the hardscaping contributes to how you use the space—and how often.
                </p>
            </Col>
            </Row>
            <Row className="gallery row mt-5 pt-5">
                <Col className="col col-lg-3 col-md-6 col-12 px-1">
                    <img className="img-fluid" src={Landscaping1} alt="" />
                </Col>
                <Col className="col col-lg-3 col-md-6 col-12 px-1">
                    <img className="img-fluid" src={Landscaping2} alt="" />
                </Col>
                <Col className="col col-lg-3 col-md-6 col-12 px-1">
                    <img className="img-fluid" src={Landscaping3} alt="" />
                </Col>
                <Col className="col col-lg-3 col-md-6 col-12 px-1">
                    <img className="img-fluid" src={Landscaping4} alt="" />
                </Col>
            </Row>
            </div>
        </main>
        </>
    )
}

export default Landscaping;