import React from "react";
import { Row, Col } from "react-bootstrap";
import './services.css';

import Bathrooms1 from '../images/bathroomt1.jpeg';
import Bathrooms2 from '../images/bathroomt2.jpeg';
import Bathrooms3 from '../images/bathroomt3.jpeg';
import Bathrooms4 from '../images/bathroomt4.jpeg';
import Bathrooms5 from '../images/bathroomst5.jpeg';

const BathroomFitting = () => {
    return (
        <>
        <header className="service-page">
            <img className="service-page-img" src={Bathrooms1} alt="" />
            <div className="overlay" />
            <h2>Bathroom Fitting</h2>
        </header>
        <main className="service-main">
            <div className="service-main-wrapper">
            <Row className="welcome row">
            <Col className="col col-lg-6 col-md-6 col-12">
                <p>
                <strong>
                The bathroom is an oasis of calm, a place to unwind and reflect. Whether you’re getting ready for the day ahead or lying back for a luxurious soak at the end of it, your bathroom is not just a place to refresh your body, but also your mind.
                </strong>
                </p>
                <p>
                As your bathroom is such an important part of your home, so you don’t want just anybody to carry out your bathroom fitting needs. That’s where we can help. At KS Projects, we have a team of professional and experienced bathroom fitters in London.
                </p>
                <p>
                Whether you need us to fit a new bathroom that you have already purchased, or you want our expert advice to help you design, supply, and fit the bathroom of your dream, we are here to help. Not only do we fit new bathrooms, but we also renovate existing bathrooms and give them the uplift your home truly deserves.
                </p>
            </Col>
            <Col className="col col-lg-6 col-md-6 col-12">
                <img src={Bathrooms2} className="img-fluid" alt="" />
            </Col>
            </Row>
            <div className="gallery-caption container-fluid py-5">
            <div className="gold display-5">
                <p>Scroll through some examples of bathroom installations we have completed.</p>
                <p>
                We will help you along the way in creating your ultimate dream space to refresh and relax in.
                </p>
            </div>
            </div>
            <Row className="gallery row">
                <Col className="col col-lg-4 col-md-6 col-12 px-1">
                    <img className="img-fluid" src={Bathrooms1} alt="" />
                    <img className="img-fluid" src={Bathrooms2} alt="" />
                </Col>
                <Col className="col col-lg-4 col-md-6 col-12 px-1">
                    <img className="img-fluid" src={Bathrooms3} alt="" />
                    <img className="img-fluid" src={Bathrooms4} alt="" />ì
                </Col>
                <Col className="col col-lg-4 col-md-6 col-12 px-1">
                    <img className="img-fluid" src={Bathrooms5} alt="" />
                </Col>
            </Row>
            </div>
        </main>
        </>
    )
}

export default BathroomFitting;