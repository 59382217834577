import React from "react";
import { Row, Col } from "react-bootstrap";
import './services.css';

import Basements2 from '../images/basements2.jpeg';
import Basements3 from '../images/basements3.jpeg';
import Basements4 from '../images/basements4.jpeg';
import Basements5 from '../images/basements5.jpeg';
import Basements6 from '../images/basements6.jpeg';
import Basements7 from '../images/basements7.jpeg';
import Basements8 from '../images/basements8.jpeg';
import Basements9 from '../images/basements9.jpeg';

const Basements = () => {
    return (
        <>
        <header className="service-page">
            <img className="service-page-img" src={Basements3} alt="" />
            <div className="overlay" />
            <h2>Basements and Underpinning</h2>
        </header>
        <main className="service-main">
            <div className="service-main-wrapper">
            <Row className="welcome row">
            <Col className="col col-lg-6 col-md-6 col-12">
                <p>
                <strong>
                Whether it is a larger entertaining space you require, a playroom for all your children’s toys and their friends to play in, an office as you plan to work from home more, maybe an annex for your nanny to have her own facilities or even a sauna/gym to come home and unwind after a hard day’s work.
                </strong>
                </p>
                <p>
                With a highly experienced Design team around us, a proven and established construction team and a fit-out arm to match we believe we can accommodate any basement build or conversion requirement in or around London.
                </p>
                <p>
                Offering creative ideas and solutions when embarking on constructing a basement, we work with our recommended architects and team of specialists to create the space that you desire.
                </p>
            </Col>
            <Col className="col col-lg-6 col-md-6 col-12">
                <img src={Basements7} className="img-fluid" alt="" />
            </Col>
            </Row>

            <Row className="gallery row mt-5 pt-5">
                <Col className="col col-lg-4 col-md-6 col-12 px-1">
                    <img className="img-fluid" src={Basements6} alt="" />
                    <img className="img-fluid" src={Basements2} alt="" />
                    <img className="img-fluid" src={Basements3} alt="" />
                </Col>
                <Col className="col col-lg-4 col-md-6 col-12 px-1">
                    <img className="img-fluid" src={Basements4} alt="" />
                    <img className="img-fluid" src={Basements5} alt="" />
                    <img className="img-fluid" src={Basements9} alt="" />
                </Col>
                <Col className="col col-lg-4 col-md-6 col-12 px-1">
                    <img className="img-fluid" src={Basements7} alt="" />
                    <img className="img-fluid" src={Basements8} alt="" />
                </Col>
            </Row>
            </div>
        </main>
        </>
    )
}

export default Basements;