import React from "react";
import { Row, Col } from "react-bootstrap";
import './services.css';

import Kitchens1 from '../images/kitchen1.jpeg';
import Kitchens2 from '../images/kitchen2.jpeg';
import Kitchens3 from '../images/kitchen3.jpeg';
import Kitchens4 from '../images/kitchen4.jpeg';
import Kitchens5 from '../images/kitchen5.jpeg';

const KitchenFitting = () => {
    return (
        <>
        <header className="service-page">
            <img className="service-page-img" src={Kitchens1} alt="" />
            <div className="overlay" />
            <h2>Kitchen Fitting</h2>
        </header>
        <main className="service-main">
            <div className="service-main-wrapper">
            <Row className="welcome row">
            <Col className="col col-lg-6 col-md-6 col-12">
                <img src={Kitchens2} className="img-fluid" alt="" />
            </Col>
            <Col className="col col-lg-6 col-md-6 col-12">
                <p>
                <strong>
                The kitchen is one of the most important rooms of your home. It is the heart of the home where delicious food is made and is one of the most used rooms in your home. Having a functional and easy to use kitchen is of upmost important and at KS Projects we work closely during the design and build process to ensure you have a kitchen which you will love for years to come.
                </strong>
                </p>
                <p>
                KS Projects have been transforming kitchens for years, we streamline the entire process and make it as quick, easy, and cost-effective for you. We handle all parts of the job, including floor fitting, electric and gas utility installation, kitchen worktops fitting, and much more. Designing and fitting a kitchen to suit your individual needs is what we specialise in. We can also help you supply your own kitchen and handle the installation process for you.
                </p>
                <p>
                To ensure customer satisfaction we work closely with our clients to find the style and fit that suits them and their needs; we offer a variety of styles ranging in design and functionality to suit any taste and budget, whether that be traditional, modern, open-plan, or intimate, a remodel or a completely new kitchen, we are here to provide!
                </p>
            </Col>
            </Row>
            <Row className="gallery row mt-5 pt-5">
                <Col className="col col-lg-4 col-md-6 col-12 px-1">
                    <img className="img-fluid" src={Kitchens1} alt="" />
                    <img className="img-fluid" src={Kitchens1} alt="" />
                </Col>
                <Col className="col col-lg-4 col-md-6 col-12 px-1">
                    <img className="img-fluid" src={Kitchens3} alt="" />
                    <img className="img-fluid" src={Kitchens4} alt="" />
                </Col>
                <Col className="col col-lg-4 col-md-6 col-12 px-1">
                    <img className="img-fluid" src={Kitchens5} alt="" />
                </Col>
            </Row>
            </div>
        </main>
        </>
    )
}

export default KitchenFitting;