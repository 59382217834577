import React from "react";
import './Services.css';
import { Row, Col } from "react-bootstrap";
import ServiceCard from "../components/ServiceCard";
import { Link } from "react-router-dom";

import LoftConversions from "../images/loft-conversions.png";
import HomeExtensions from "../images/home-extensions.png";
import Basements from '../images/basements.jpeg';
import Landscaping from '../images/landscaping.png';
import BathroomFitting from '../images/bathroom-fitting.png';
import KitchenFitting from "../images/kitchen-fitting.png";
import Painting from '../images/painting-and-decorating.png';
import Carpentry from "../images/carpentry.png";

const Services = () => {

    function restoreScroll() {
        window.scrollTo(0,0);
    }

    return (
        <>
        <header>
          <div className="overlay" />
          <section className="services-header">
            <h2 className="main-title">Our Services</h2>
          </section>
        </header>
        <main>
            <section className="services">
                <h3>Construction and home renovations in North London</h3>
                <Row className="row">
                    <Col className="col col-lg-3 col-md-6 col-12"><Link className="Link" onClick={restoreScroll} to="/loft-conversions"><ServiceCard name="Loft Conversions" imgSrc={LoftConversions} /></Link></Col>
                    <Col className="col col-lg-3 col-md-6 col-12"><Link className="Link" onClick={restoreScroll} to="/home-extensions"><ServiceCard name="Home Extensions" imgSrc={HomeExtensions} /></Link></Col>
                    <Col className="col col-lg-3 col-md-6 col-12"><Link className="Link" onClick={restoreScroll} to="/basements"><ServiceCard name="Basements and Underpinning" imgSrc={Basements} /></Link></Col>
                    <Col className="col col-lg-3 col-md-6 col-12"><Link className="Link" onClick={restoreScroll} to="/landscaping"><ServiceCard name="Landscaping" imgSrc={Landscaping} /></Link></Col>
                    <Col className="col col-lg-3 col-md-6 col-12"><Link className="Link" onClick={restoreScroll} to="/bathroom-fitting"><ServiceCard name="Bathroom Fitting" imgSrc={BathroomFitting} /></Link></Col>
                    <Col className="col col-lg-3 col-md-6 col-12"><Link className="Link" onClick={restoreScroll} to="/kitchen-fitting"><ServiceCard name="Kitchen Fitting" imgSrc={KitchenFitting} /></Link></Col>
                    <Col className="col col-lg-3 col-md-6 col-12"><Link className="Link" onClick={restoreScroll} to="/painting-and-decorating"><ServiceCard name="Painting and Decorating" imgSrc={Painting} /></Link></Col>
                    <Col className="col col-lg-3 col-md-6 col-12"><Link className="Link" onClick={restoreScroll} to="/carpentry"><ServiceCard name="Carpentry" imgSrc={Carpentry} /></Link></Col>
                </Row>
            </section>
        </main>
        </>
    )
}

export default Services;