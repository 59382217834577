import React from "react";
import { Row, Col } from "react-bootstrap";
import './services.css';

import Extensions1 from '../images/extensions1.jpeg';
import Extensions2 from '../images/extensions2.jpeg';
import Extensions3 from '../images/extensions3.jpeg';
import Extensions4 from '../images/extensions4.jpeg';
import Extensions5 from '../images/extensions5.jpeg';
import Extensions6 from '../images/extensions6.jpeg';
import Extensions7 from '../images/extensions7.jpeg';
import Extensions8 from '../images/extensions8.jpeg';
import Extensions9 from '../images/extensions9.jpeg';
import Extensions from '../images/extensions11.jpeg';

const HomeExtensions = () => {
    return (
        <>
        <header className="service-page">
            <img className="service-page-img" src={Extensions1} alt="" />
            <div className="overlay" />
            <h2>Home Extensions</h2>
        </header>
        <main className="service-main">
            <div className="service-main-wrapper">
            <Row className="welcome row">
            <Col className="col col-lg-6 col-md-6 col-12">
                <img src={Extensions} className="img-fluid" alt="" />
            </Col>
            <Col className="col col-lg-6 col-md-6 col-12">
                <p>
                <strong>
                Add extra floor space to your home. Extensions give the opportunity to create a new family space with a kitchen or living room ensuring there is ample space to relax and unwind.
                </strong>
                </p>
                <p>
                A house extension is a versatile way of adding extra space to your home, and there are many ways you could go about doing so. You could go for something really simple or something more grandiose like a double storey wrap around extension.
                </p>
                <p>
                Which style our customers choose usually comes down to budget and personal preference. Architectural capacity is also a vital factor and one of our architectural specialists will be happy to survey your property, work out exactly what’s possible to do with your home and come up with a tailored solution to fit your individual needs.
                </p>
            </Col>
            </Row>
            <div className="gallery-caption container-fluid py-5">
            <div className="gold display-5">
                <p>Scroll through some examples of extensions and house refurbishments.</p>
                <p>
                The character doesn't just end inside of your home but can be expressed on the outside too.
                </p>
            </div>
            </div>
            <Row className="gallery row">
                <Col className="col col-lg-4 col-md-6 col-12 px-1">
                    <img className="img-fluid" src={Extensions1} alt="" />
                    <img className="img-fluid" src={Extensions2} alt="" />
                    <img className="img-fluid" src={Extensions3} alt="" />
                </Col>
                <Col className="col col-lg-4 col-md-6 col-12 px-1">
                    <img className="img-fluid" src={Extensions4} alt="" />
                    <img className="img-fluid" src={Extensions5} alt="" />
                    <img className="img-fluid" src={Extensions6} alt="" />
                </Col>
                <Col className="col col-lg-4 col-md-6 col-12 px-1">
                    <img className="img-fluid" src={Extensions7} alt="" />
                    <img className="img-fluid" src={Extensions8} alt="" />
                    <img className="img-fluid" src={Extensions9} alt="" />
                </Col>
            </Row>
            </div>
        </main>
        </>
    )
}

export default HomeExtensions;