import React from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { RouterProvider, createBrowserRouter, createRoutesFromElements, Route, Navigate } from "react-router-dom"

import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';

import Contact from './pages/Contact';
import Root from './pages/Root';

import LoftConversions from './services/LoftConversions';
import HomeExtensions from './services/HomeExtensions';
import Basements from './services/Basements';
import Landscaping from './services/Landscaping';
import BathroomFitting from './services/BathroomFitting';
import KitchenFitting from './services/KitchenFitting';
import Painting from './services/Painting';
import Carpentry from './services/Carpentry';

import './App.css';

function App() {
  const router = createBrowserRouter(createRoutesFromElements(
    <Route path="/" element={ <Root /> }>
      <Route index element={ <Home />}/>
      <Route path="about" element={ <About /> }/>
      <Route path="services" element={ <Services /> }/>
      <Route path="contact" element={ <Contact /> }/>
      
      <Route path="loft-conversions" element={ <LoftConversions /> }/>
      <Route path="home-extensions" element={ <HomeExtensions /> }/>
      <Route path="basements" element={ <Basements /> }/>
      <Route path="landscaping" element={ <Landscaping /> }/>
      <Route path="bathroom-fitting" element={ <BathroomFitting /> }/>
      <Route path="kitchen-fitting" element={ <KitchenFitting /> }/>
      <Route path="painting-and-decorating" element={ <Painting /> }/>
      <Route path="carpentry" element={ <Carpentry /> }/>
    </Route>
  ))

  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;