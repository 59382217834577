import React from "react";
import './Contact.css';

const Contact = () => {
    return (
        <>
        <header>
          <div className="contact-overlay" />
          <section className="contact-header">
            <h2 className="main-title">Get in Touch</h2>
          </section>
        </header>
        <main>
        <section className="contact-text">
            <div>
            <p>
                Give us a call or email us for a free quote and advice. We can't wait to
                get started to transform your home and make your dreams come true!
            </p>
            <h3>Telephone </h3>
            <div className="gold-small">☎ 07873458383</div>
            <h3>Email </h3>
            <div className="gold-small">✉ kaylan@ksproject.co.uk</div>
            <h3>Follow us on socials </h3>
            <div className="gold-small">@ksprojects _ksprojects</div>
            </div>
        </section>
        </main>
        </>
    )

}

export default Contact;
