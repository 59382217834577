import React from "react";
import './Nav.css';
import GoldButton from "./GoldButton";
import Logo from '../images/logocut.png';
import NavToggle from './hamburger.svg';
import { useState } from "react";
import { NavLink } from "react-router-dom";

const Nav = () => {
    const [isExpanded, setIsExpanded] = useState(false);

    function restoreScroll() {
        window.scrollTo(0,0);
    }
    
    return (
        <nav id="nav">
            <div className="navbar container-fluid">
                <div className="logo-wrapper">
                <NavLink to="/" className="NavLink-logo" onClick={restoreScroll}>
                    <img
                    className="logo img-fluid"
                    src={Logo}
                    alt="KS Projects"
                    />
                </NavLink>
                </div>
                <div className="navigation">
                <ul className="nav-links">
                    <li className="nav-item"><NavLink to="/" className="NavLink" onClick={restoreScroll}><div className="nav-button">Home</div></NavLink></li>
                    <li className="nav-item"><NavLink to="/about" className="NavLink" onClick={restoreScroll}><div className="nav-button">About</div></NavLink></li>
                    <li className="nav-item"><NavLink to="/services" className="NavLink" onClick={restoreScroll}><div className="nav-button">Services</div></NavLink></li>
                    <li className="nav-item"><NavLink to="/contact" className="NavLink" onClick={restoreScroll}><GoldButton text="Contact Us" /></NavLink></li>
                </ul>
                </div>
            </div>
            <div className="responsive-navbar">

            <div id="nav-toggle">menu&nbsp;&nbsp;<img src={NavToggle} alt="Menu" className="nav-open" onClick={() => {
                 setIsExpanded(!isExpanded);
            }} />
                </div>
                {isExpanded ? 
                <ul className="nav-links" id="responsive-nav-links">
                            <li className="nav-item"><NavLink to="/" className="NavLink" onClick={() => setIsExpanded(false)}>HOME</NavLink></li>
                            <li className="nav-item"><NavLink to="/about" className="NavLink" onClick={() => setIsExpanded(false)}>ABOUT</NavLink></li>
                            <li className="nav-item"><NavLink to="/services" className="NavLink" onClick={() => setIsExpanded(false)}>SERVICES</NavLink></li>
                            <li className="nav-item nav-item-contact"><NavLink to="/contact" className="NavLink" onClick={() => setIsExpanded(false)}><GoldButton text="Contact Us" /></NavLink></li>         
                 </ul>
                 : '' }
            </div>
        </nav>

    )
}

export default Nav;