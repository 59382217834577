import React from "react";
import { Row, Col } from "react-bootstrap";
import './services.css';

import Loft from '../images/loft.jpeg';
import Lofts1 from '../images/lofts1.jpeg';
import Lofts2 from '../images/lofts2.jpeg';
import Lofts3 from '../images/lofts3.jpeg';
import Lofts4 from '../images/lofts4.jpeg';
import Lofts5 from '../images/lofts5.jpeg';
import Lofts6 from '../images/lofts6.jpeg';
import Lofts7 from '../images/lofts7.jpeg';
import Lofts8 from '../images/lofts8.jpeg';
import Lofts9 from '../images/lofts9.jpeg';

const LoftConversions = () => {
    return (
        <>
        <header className="service-page">
            <img className="service-page-img" src={Lofts1} alt="" />
            <div className="overlay" />
            <h2>Loft Conversions</h2>
        </header>
        <main className="service-main">
            <div className="service-main-wrapper">
            <Row className="welcome row">
            <Col className="col col-lg-6 col-md-6 col-12">
                <p>
                <strong>
                    Extending your home upwards as opposed to outwards can sometimes be
                    the only option to house a growing family and maximise the comfort
                    and value of your home.
                </strong>
                </p>
                <p>
                Loft conversions are a great way to add substantial value and create
                extra space. We have transformed lofts into dream bedrooms, fun
                playrooms, luxurious libraries and spacious offices.
                </p>
                <p>
                All required building and planning applications can be managed and
                included within our quote. Your own dedicated team will be available
                to answer questions and queries before, during and after the build.
                Our fabulous team of builders have completed numerous projects in and
                around London in homes of every shape and size from smaller
                mid-terraced to larger detached properties.
                </p>
            </Col>
            <Col className="col col-lg-6 col-md-6 col-12">
                <img src={Loft} className="img-fluid" alt="" />
            </Col>
            </Row>
            <div className="gallery-caption container-fluid py-5">
            <div className="gold display-5">
                <p>Look below through some examples of loft conversions.</p>
                <p>
                We will help you along the way in creating your ultimate dream space
                to live and relax in.
                </p>
            </div>
            </div>
            <Row className="gallery row">
                <Col className="col col-lg-4 col-md-6 col-12 px-1">
                    <img className="img-fluid" src={Lofts1} alt="" />
                    <img className="img-fluid" src={Lofts2} alt="" />
                    <img className="img-fluid" src={Lofts3} alt="" />
                </Col>
                <Col className="col col-lg-4 col-md-6 col-12 px-1">
                    <img className="img-fluid" src={Lofts7} alt="" />
                    <img className="img-fluid" src={Lofts8} alt="" />
                    <img className="img-fluid" src={Lofts9} alt="" />
                </Col>
                <Col className="col col-lg-4 col-md-6 col-12 px-1">
                    <img className="img-fluid" src={Lofts4} alt="" />
                    <img className="img-fluid" src={Lofts5} alt="" />
                    <img className="img-fluid" src={Lofts6} alt="" />
                </Col>
            </Row>
            </div>
        </main>
        </>
    )
}

export default LoftConversions;