import React from "react";
import { Col } from "react-bootstrap";
import './ServiceCard.css';

const ServiceCard = ({
    name,
    imgSrc,
}) => {
    return (
            <Col className="service-card col col-xl-3 col-md-6 col-12">
                <div className="service-card-content">
                    <img src={imgSrc} alt={name} />
                    <h4>{name}</h4> 
                </div>
            </Col>
    )
}

export default ServiceCard;