import React from "react";
import { Row, Col } from "react-bootstrap";
import './About.css';
import GoldButton from "../components/GoldButton";
import { Link } from "react-router-dom";

import Img1 from '../images/carpentry1.jpeg';
import Img2 from '../images/ct2.jpeg';

const About = () => {
  function restoreScroll() {
    window.scrollTo(0,0);
}

    return (
      <>
        <header>
          <div className="about-overlay" />
          <section className="about-header">
            <h2 className="main-title">About Us</h2>
          </section>
        </header>
        <main className="about-main">
          <section className="about">
            <Row className="row about-row-1">
              <Col className="col col-md-6 col-12">
                <img src={Img1} alt="" />
              </Col>
              <Col className="col col-md-6 col-12">
                <div>
                  <p>
                    We are a team of professional construction contractors, based in
                    London and the surrounding areas, offering a wide range of
                    construction services from complete home renovations to basements
                    and bathrooms.
                  </p>
                  <p>
                    We have completed a number of complex jobs and produce fantastic
                    results each and every time, ensuring the client is satisfied and a
                    high-end finish is always achieved. We specialise in renovations,
                    basements, loft conversions, home extensions and a whole host of
                    other construction activities to ensure we can transform your home.
                    We work closely with the client to ensure we complete work in an
                    efficient manner with minimal disruption.
                  </p>
                </div>
              </Col>
            </Row>
            <Row className="row">
              <Col className="col col-md-6 col-12">
                <div>
                  <p>
                    We pride ourselves on reliability, communication, quality and always
                    endeavour to ensure our reputation is maintained to a high standard.
                  </p>
                  <p>
                    KS Projects was established in 2023. During the last
                    few years of growth and building partnerships, we've gained
                    experience in construction and refurbishment, delivering projects
                    for our clients all over London.
                  </p>
                  <p>
                    Our team brings its technical and project management expertise, as
                    well as exemplary craftsmanship to every building project, helping
                    to make our clients dream come true. The team consists of
                    architects, engineers and project managers, as well as established
                    tradesmen who have a wide range of experiences and knowledge,
                    dealing with different materials, finishes and building types.
                  </p>
                </div>
              </Col>
              <Col className="col col-md-6 col-12">
                <img src={Img2} alt="" />
              </Col>
            </Row>
            <Link className="Link buttonLink" to="/services" onClick={restoreScroll}><GoldButton className="GoldButton" text="Explore our services →" /></Link>
          </section>
        </main>
        </>
    )
}

export default About;
