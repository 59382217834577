import React from "react";
import './Footer.css';
import Logo from '../images/logo.png';
import { NavLink, Link } from "react-router-dom";

const Footer = () => {
    function restoreScroll() {
        window.scrollTo(0,0);
    }

    return (
        <footer>
            <div className="bottom-nav row">
                <div className="col col-lg-4 col-md-4 col-12">
                <div className="logo">
                    <img className="footer-logo" src={Logo} alt="KS Projects" />
                </div>
                </div>
                <div className="col col-lg-4 col-md-4 col-12">
                <ul className="nav d-flex flex-column">
                    <li className="nav-item py-3 title">
                    </li>
                    <li className="nav-item py-3">
                    KS Projects
                    <br />
                    </li>
                    <li className="nav-item py-3">
                    kaylan@ksproject.co.uk
                    <br />
                    </li>
                </ul>
                </div>
                <div className="col col-lg-4 col-md-4 col-12">
                <ul className="nav d-flex flex-column">
                <li className="nav-item"><NavLink to="/" className="NavLink" onClick={restoreScroll}><div className="nav-button">Home</div></NavLink></li>
                    <li className="nav-item"><NavLink to="/about" className="NavLink" onClick={restoreScroll}><div className="nav-button">About</div></NavLink></li>
                    <li className="nav-item"><NavLink to="/services" className="NavLink" onClick={restoreScroll}><div className="nav-button">Services</div></NavLink></li>
                    <li className="nav-item"><NavLink to="/contact" className="NavLink" onClick={restoreScroll}><div className="nav-button">Contact</div></NavLink></li>
                </ul>
                </div>
            </div>
            <div className="social container-fluid">
                <a href="https://www.instagram.com/_ksprojects/?hl=en" target="_blank">
                <img src="images/instagram.png" alt="" />
                </a>
                <a href="https://www.youtube.com/@_ksprojects" target="_blank">
                <img src="images/youtube.png" alt="" />
                </a>
                <a href="https://www.tiktok.com/@_ksprojects" target="_blank">
                <img src="images/tiktok.png" alt="" />
                </a>
            </div>
        </footer>
    )
}

export default Footer;
