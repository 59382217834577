import React from "react";
import { Row, Col } from "react-bootstrap";
import './services.css';

import Painting1 from '../images/painting1.jpeg';
import Painting2 from '../images/painting2.jpeg';
import Painting3 from '../images/painting3.jpeg';
import Painting4 from '../images/painting4.jpeg';
import Painting5 from '../images/painting5.jpeg';

const Painting = () => {
    return (
        <>
        <header className="service-page">
            <img className="service-page-img" src={Painting1} alt="" />
            <div className="overlay" />
            <h2>Painting and Decorating</h2>
        </header>
        <main className="service-main">
            <div className="service-main-wrapper">
            <Row className="welcome row">
            <Col className="col col-lg-6 col-md-6 col-12">
                <p>
                <strong>
                Painting the interior of your home is a cost-efficient way to breathe new life into your property. Statistically, we spend over 80% of our time indoors, so it’s only common sense to want to make sure our living space is as beautiful and elegant as possible.
                </strong>
                </p>
                <p>
                Prior to commencing the exterior painting of your home, thorough preparation will be carried out, this is a very vital step in producing the excellent results we always deliver. This involves filling gaps, removing loose scraped paint, and making sure any other imperfections are rectified. Once this is complete and we have covered all doors and windows we can begin the painting work to the highest level of detail. Our aim is to make you mesmerised by the beauty of the outside of your home once we are done.
                </p>
            </Col>
            <Col className="col col-lg-6 col-md-6 col-12">
                <img src={Painting3} className="img-fluid" alt="" />
            </Col>
            </Row>
            <Row className="gallery row mt-5 pt-5">
                <Col className="col col-lg-4 col-md-6 col-12 px-1">
                    <img className="img-fluid" src={Painting1} alt="" />
                    <img className="img-fluid" src={Painting2} alt="" />
                </Col>
                <Col className="col col-lg-4 col-md-6 col-12 px-1">
                    <img className="img-fluid" src={Painting3} alt="" />
                    <img className="img-fluid" src={Painting4} alt="" />
                </Col>
                <Col className="col col-lg-4 col-md-6 col-12 px-1">
                    <img className="img-fluid" src={Painting5} alt="" />
                </Col>
            </Row>
            </div>
        </main>
        </>
    )
}

export default Painting;